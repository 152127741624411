import styled, { css } from "styled-components";
import { DESKTOP_1100, DESKTOP_1280, MOBILE_460 } from "../../styles/sizes";

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 320px;
  align-items: center;

  @media (max-width: ${MOBILE_460}px) {
    height: 290px;
  }
`;

export const Logo = styled.img<IDivProps>`
  max-width: 220px;
  height: 107px;
`;

export const PrizeTitle = styled.div`
  font-weight: bold;
  margin-top: 20px;
`;

export const PrizeText = styled.div`
  font-weight: bold;
  margin-top: 15px;
  color: #414141;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
`;
export const CarrouselSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CarrouselContent = styled.div<{ mobile?: boolean }>`
  width: 90%;
  margin-top: 35px;

  .swiper-horizontal, .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: -55px;
  }
  .swiper-pagination-bullet-active {
    background-color: red !important;
  }
  .swiper {
    position: initial;
  }
  @media (max-width: ${DESKTOP_1100}px) {
    display: none;
  }
  ${(props) =>
    props.mobile &&
    `
  display: none;
  @media(max-width: ${DESKTOP_1100}px) {
    display: block;
  }
  `}
`;

export const AwardsNavigationButton = styled.img<INavigationButtonProps>`
  width: calc(1vw + 25px);
  height: calc(1vw + 25px);
  cursor: pointer;
  transform: ${(props) => (props.type === "prev" ? "scale(-1)" : "")};
  font-size: 30px;
  position: absolute;
  top: 46%;
  z-index: 20;
  ${(props) =>
    props.type === "next"
      ? css`
          right: 2vw;
        `
      : css`
          left: 2vw;
        `};
  @media (max-width: ${DESKTOP_1280}px) {
    ${(props) =>
    props.type === "next"
      ? css`
          right: 8vw;
        `
      : css`
          left: 8vw;
        `};
  }
  @media (max-width: ${DESKTOP_1100}px) {
    display: none;
  }
`;

interface INavigationButtonProps {
  type?: string;
}

interface IDivProps {
  moreWidth?: boolean;
}
