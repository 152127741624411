import React, { useState } from "react";

import grayArrow from "../../images/grayArrow.svg";
import purpleArrow from "../../images/purpleArrow.png";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./defaultStyles.css";

// import required modules
import { Pagination, Autoplay } from "swiper";

import {
  SlideContainer,
  Logo,
  PrizeTitle,
  PrizeText,
  CarrouselSection,
  CarrouselContent,
  AwardsNavigationButton,
} from "./styles";

const SliderControls: React.FC<{ side: string }> = (props) => {
  const [isHovered, setHovered] = useState(false);
  const swiper = useSwiper();
  const handleNext = () => {
    swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.slidePrev();
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  if (props.side === "right")
    return (
      <AwardsNavigationButton
        src={isHovered ? purpleArrow : grayArrow}
        type={"next"}
        onClick={() => handleNext()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    );
  if (props.side === "left")
    return (
      <AwardsNavigationButton
        src={isHovered ? purpleArrow : grayArrow}
        type={"prev"}
        onClick={() => handlePrev()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    );
};

export const AwardsCarrousel: React.FC = ({ items }: any) => {
  items.map((item: any) => {
  });
  return (
    <CarrouselSection>
      <CarrouselContent>
        <Swiper
          loop={true}
          autoplay={false}
          speed={700}
          spaceBetween={30}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 2,
          }}
          slidesPerView={3}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          <SliderControls side="left" />
          <SliderControls side="right" />
          {items.map((item: any) => (
            <SwiperSlide>
              <SlideContainer>
                <Logo
                  src={item.attributes.LogoImage.data.attributes.url}
                  alt={item.attributes.LogoTitle}
                />
                <PrizeTitle>{item.attributes.LogoTitle}</PrizeTitle>
                <PrizeText>
                  {item.attributes.LogoSubtitle}
                  <br />
                  {item.attributes.LogoSubtitleLine2}
                </PrizeText>
              </SlideContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </CarrouselContent>
      <CarrouselContent mobile>
        <Swiper
          loop={true}
          autoplay={false}
          speed={700}
          spaceBetween={30}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 2,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          <SliderControls side="left" />
          <SliderControls side="right" />
          {items.map((item: any) => (
            <SwiperSlide>
              <SlideContainer>
                <Logo
                  src={item.attributes.LogoImage.data.attributes.url}
                  alt={item.attributes.LogoTitle}
                />
                <PrizeTitle>{item.attributes.LogoTitle}</PrizeTitle>
                <PrizeText>
                  {item.attributes.LogoSubtitle}
                  <br />
                  {item.attributes.LogoSubtitleLine2}
                </PrizeText>
              </SlideContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </CarrouselContent>
    </CarrouselSection>
  );
};
