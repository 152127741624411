import React, { useState } from "react";
import grayArrow from "../../images/grayArrow.svg";
import purpleArrow from "../../images/purpleArrow.png";
import quoteIcon from "./assets/quoteIcon.svg";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./defaultStyles.css";

// import required modules
import { Autoplay } from "swiper";

import { CultureContainer, SubtitleCulture } from "../../styles/commons";

import {
  SlideContainer,
  QuoteName,
  QuoteText,
  CarrouselSection,
  CarrouselContent,
  AwardsNavigationButton,
  QuoteRole,
  Quote,
  QuoteSlide,
} from "./styles";

export const PeopleSlider: React.FC<IProps> = (props: IProps) => {
  const { activeCategory } = props;
  const quotes = props.quotes;
  const SliderControls: React.FC<{ side: string }> = (props) => {
    const swiper = useSwiper();
    const handleNext = () => {
      swiper.slideNext();
    };
    const handlePrev = () => {
      swiper.slidePrev();
    };

    const [isHovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
      setHovered(true);
    };

    const handleMouseLeave = () => {
      setHovered(false);
    };

    if (props.side === "right")
      return (
        <AwardsNavigationButton
          src={isHovered ? purpleArrow : grayArrow}
          type={"next"}
          onClick={() => handleNext()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      );
    if (props.side === "left")
      return (
        <AwardsNavigationButton
          src={isHovered ? purpleArrow : grayArrow}
          type={"prev"}
          onClick={() => handlePrev()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      );
  };
  const slider = () => {
    return (
      <CarrouselSection>
        <CarrouselContent>
          <Swiper
            loop={true}
            speed={700}
            autoplay={{
              delay: 8000,
            }}
            slidesPerView={1}
            spaceBetween={30}
            breakpoints={{
              210: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SliderControls side="left" />
            <SliderControls side="right" />

            {quotes.map((item: any) => {
              return (
                <SwiperSlide>
                  <QuoteSlide>
                    <SlideContainer>
                      <Quote leftSide src={quoteIcon} />
                      <QuoteText employees>
                        {item.attributes.quote_text}
                      </QuoteText>
                      <QuoteName>{item.attributes.author}</QuoteName>
                      <QuoteRole>{item.attributes.position}</QuoteRole>
                      <Quote src={quoteIcon} />
                    </SlideContainer>
                  </QuoteSlide>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </CarrouselContent>
      </CarrouselSection>
    );
  };

  return (
    <CultureContainer
      paddingTop={"20px"}
      paddingTopTablet={"10px"}
      paddingTopMobile="0px"
    >
      {props.innerTitle && (
        <SubtitleCulture style={{ color: props.fontColor }}>
          {activeCategory == "EMPLOYEES"
            ? "What the team says"
            : "How people see us"}
        </SubtitleCulture>
      )}
      {slider()}
    </CultureContainer>
  );
};

interface IProps {
  innerTitle?: boolean;
  fontColor?: string;
  activeCategory: string;
  quotes: any;
}
