import styled, { css, keyframes } from "styled-components";
import {
  TABLET_992,
  TABLET_800,
  MOBILE_460,
  MOBILE_500,
  MOBILE_380,
  MOBILE_320,
  DESKTOP_1200,
} from "../../styles/sizes";

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
  width: 60%;
  padding: 0 10%;
  border-radius: 8px;
  position: relative;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    margin-bottom: 30px;
  }
  @media (max-width: ${TABLET_800}px) {
    display: flex;
  }
  @media (max-width: ${MOBILE_500}px) {
    margin-bottom: 0;
    display: flex;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: flex;
  }
  @media (max-width: ${MOBILE_380}px) {
    display: flex;
  }
`;

export const Logo = styled.img`
  width: 70%;
  height: 107px;
`;

export const QuoteName = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 20px;
  color: #ff0000;

  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 20px;
    padding-top: 30px;
  }
  @media (max-width: ${TABLET_800}px) {
    font-size: 16px;
    padding-top: 20px;
  }
  @media (max-width: ${MOBILE_500}px) {
    font-size: 16px;
  }
`;

export const QuoteText = styled.div<IQuoteProps>`
  font-weight: bold;
  padding-top: 50px;
  color: #1A1A1D;
  font-family: "Montserrat-Light";
  font-size: 24px;
  font-weight: 200;
  line-height: 30px;
  font-style: italic;
  display: block;
  text-align: start;

  @media (max-width: ${DESKTOP_1200}px) {
    padding-top: 55px;
  }
  @media (max-width: ${TABLET_800}px) {
    ${(props) =>
      props.employees
        ? `font: normal 18px/22px "Montserrat"; -webkit-line-clamp: 8;`
        : `font: normal 16px/20px "Montserrat";`}
    padding-top: 50px;
  }
  @media (max-width: ${MOBILE_500}px) {
    padding-top: 50px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding-top: 50px;
  }
  @media (max-width: ${MOBILE_380}px) {
  }
`;
export const CarrouselSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const QuoteSlide = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CarrouselContent = styled.div`
  width: 90%;
  margin-top: 15px;
  .swiper-pagination-bullet-active {
    background-color: red !important;
  }
`;

export const HowSeeUsContainer = styled.div`
  margin-bottom: 60px;
`;

export const FilterSection = styled.div`
  width: fit-content;
  height: fit-content;
  margin: 20px auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: ${MOBILE_500}px) {
    width: 100%;
  }
`;
export const FilterList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
export const FilterItem = styled.p`
  width: fit-content;
  font-size: 18px;
  height: 100%;
  margin: 10px 12px;
  padding: 0;
  word-wrap: normal;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: ${({ fontColor }: { fontColor: string }) => fontColor};

  @media (max-width: 1023px) {
    font-size: 1.8vw;
    margin: 10px 20px;
  }

  @media (max-width: ${TABLET_800}px) {
    font-size: 16px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
`;
export const FilterBar = styled.div`
  width: 100%;
  height: 3px;
  background: #c9c9c9;
  position: relative;
`;

const move = (from: string, to: string) => keyframes`
    from { left: ${from} };
    to { left: ${to} };
`;

export const QuoteRole = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #7C7C7C;
  padding-bottom: 50px;
  text-align: left;
  padding-top: 10px;

  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 20px;
    padding-bottom: 45px;
  }
  @media (max-width: ${TABLET_800}px) {
    font-size: 16px;
    padding-bottom: 45px;
  }
  @media (max-width: ${MOBILE_500}px) {
    font-size: 16px;
    padding-bottom: 40px;
  }
  @media (max-width: ${MOBILE_380}px) {
    font-size: 16px;
  }
`;

export const Quote = styled.img<IFilterItem>`
  position: absolute;
  width: 70px;
  height: 60px;
  ${(props) => (props.leftSide ? "left: 10%" : "right: 10%")};
  ${(props) => !props.leftSide && " transform: rotate(180deg);"};

  ${(props) =>
    props.leftSide
      ? css`
          top: -20px;
          left: 10%;
        `
      : css`
          bottom: -20px;
          right: 10%;
          transform: rotate(180deg);
        `}

  @media(max-width: ${MOBILE_460}px) {
    width: 60px;
    height: 50px;
  }
  @media (max-width: ${MOBILE_380}px) {
    width: 60px;
    height: 50px;
  }
`;

export const FilterIndicator = styled.div<IFilterIndicator>`
  width: ${(props) => {
    const category = props.categoryWidth;
    if (category === "EMPLOYEES") {
      return "55%";
    } else if (category === "CLIENTS") {
      return "40%";
    } else if (category === "Content Production") {
      return "21%";
    } else if (category === "Marketing Strategy") {
      return "20%";
    } else if (category === "Business Intelligence") {
      return "22%";
    } else return "5.5%";
  }};
  height: 5px;
  background: #223238d0;
  position: absolute;
  animation: ${(props) => move(props.from, props.to)} 1s forwards;
  transition: transform 1s;
  transition: width 1s ease-in-out;
  margin-top: -1px;
`;

export const AwardsNavigationButton = styled.img<INavigationButtonProps>`
  width: calc(1vw + 25px);
  height: calc(1vw + 25px);
  cursor: pointer;
  transform: ${(props) => (props.type === "prev" ? "scale(-1)" : "")};
  font-size: 30px;
  position: absolute;
  top: 40%;
  z-index: 20;
  ${(props) =>
    props.type === "next"
      ? css`
          right: 0vw;
        `
      : css`
          left: 0vw;
        `};
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;
interface IFilterIndicator {
  from: string;
  to: string;
  categoryWidth?: string;
}
interface IFilterItem {
  isActive?: boolean;
  isSeeMore?: boolean;
  activeCategory?: string;
  leftSide?: boolean;
}
interface ISeeMoreProps {
  fiveMoreProjects?: boolean;
  isActive?: boolean;
}

interface INavigationButtonProps {
  type?: string;
}
interface IQuoteProps {
  employees?: boolean;
}
